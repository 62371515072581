.image-detail {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 50px;
}

.image-detail h1 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

.image-detail h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.image-detail h3 {
  font-size: 1vw;
  padding-left: 50px;
}

.image-detail h4 {
  font-size: 1.5vw;
}

.image-detail h5 {
  padding-left: 50px;
  font-size: 1.2vw;
}

.image-detail .category-container {
  margin-bottom: 40px; /* Added margin-bottom to separate categories */
}

.image-detail .image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.image-detail .image-grid img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 480px) {
  .image-detail h1 {
    font-size: 6vw;
  }

  .image-detail h2 {
    font-size: 5vw;
  }

  .image-detail h3 {
    font-size: 3.5vw;
    padding-left: 20px;
  }

  .image-detail h4 {
    font-size: 4.5vw;
  }

  .image-detail h5 {
    font-size: 3.5vw;
    padding-left: 20px;
  }

  .image-detail .image-grid {
    grid-template-columns: 1fr; /* Make the images stack vertically on small screens */
    gap: 10px;
  }
}
