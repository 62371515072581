.team-page {
  text-align: center;
  margin-top: 50px;
}

.team-heading {
  font-size: 50px;
  margin-bottom: 80px;
}

.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.team-img-circle {
  width: 450px;
  height: 450px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.team-img-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-description {
  max-width: 450px;
}

.team-description h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.team-description h5 {
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
}

.team-description p {
  font-size: 16px;
  line-height: 1.7;
  text-align: left;
  font-weight: 500;
  font-family: Times New Roman, serif;
  color: black;
  text-align: justify;
  margin-right: 2vh;
}



.team-img-rectangle {
  width: 1100px;
  height: 600px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 20px;
}

.team-img-rectangle img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.team-img-rectangle:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

/* Adjustments for Small Screens */
@media (max-width: 770px) {
  .team-heading {
    font-size: 36px;
    margin-bottom: 50px;
  }

  .team-member {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .team-img-circle {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }

  .team-description {
    max-width: none;
    text-align: center;
  }

  .team-description h3 {
    text-align: center;
  }

  .team-description h5 {
    text-align: center;
  }

  .team-description p {
    text-align: justify;

  }

  .team-img-rectangle {
    width: 300px;
    height: 300px;
    border-radius: 10px;
  }

  .team-page {
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }
}