.carousel {
  width: 80%; 
  margin: 100px auto 0;
  position: relative;
}

.carousel-item img {
  width: 100%; 
  max-width: 800px; 
  max-height: 1000px;
  object-fit: cover; 
  display: block; 
  margin: 0 auto; 
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black; 
  width: 30px; 
  height: 30px;
  border-radius: 50%; 
}

.sr-only {
  display: none;
}

.bottom-spacing {
  margin-bottom: 100px;
}


@media (max-width: 768px) {
  .carousel {
    margin-top: 200px; 
  }
}
