.navbar {
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: white;
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  margin-right: auto;
}

.navbar-brand img {
  width: 120px;
  height: auto;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav-item {
  margin: 0 65px;
}

.nav-link {
  font-weight: 600 !important;
  color: black !important;
  font-style: normal;
}

.nav-font {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500 !important;
}

.nav-font:hover {
  color: #FF7D00;
}

.socialtop {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.top-social a {
  padding: 1px;
  color: black;
  text-decoration: none;
  margin-right: 10px;
}

.top-social a:hover {
  color: #FF7D00;
}

.carousel-control-next-icon:after {
  font-size: 45px;
  color: black;
}

.carousel-control-prev-icon:after {
  font-size: 45px;
  color: black;
}

.a:hover {
  color: #FF7D00;
}




@media only screen and (max-width: 768px) {
  .navbar {

    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .nav-item {
    margin: 5px 0;
    width: 100%;
  }

  .navbar-nav .social-icons {
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px 0;
  
  }

  .navbar-nav li a {
    padding: 10px;
    width: 100%;
  }

  .top-social {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .top-social a {
    padding-right: 5px;
  }

  .navbar-collapse {
    width: 100%;
  }
}